  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/2-5-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li>Après un incendie</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Les conséquences d’un incendie sur votre habitation</h1>

<p>Les conséquences d’un incendie sur une habitation ne s’arrêtent pas simplement aux éléments détériorés voir détruits par les flammes.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Après un incendie" />
</div>

<p>Bien souvent, la phase de après sinistre est délicate et nécessite une expertise plus approfondie afin de savoir par exemple, l’impact de la chaleur sur les éléments structurels de l’ouvrage ou encore l’impact des suies chlorées.</p>
<h2>Pourquoi être assisté d’un Expert d’assuré pour votre sinistre incendie ? </h2>

<p>Les dommages causés par un incendie sont souvent importants et les frais de remise en état peuvent vite être conséquents pour l’assuré. Un différentiel important existe entre le montant indemnitaire proposé par l’assurance et la réalité des dommages sur vos biens.</p>
<p>C’est pourquoi vous faire assister d’un Expert incendie vous permet de contrebalancer ces propositions d’indemnisation souvent insuffisantes au vue du préjudice subi.</p>

<p>Après la réalisation d’une expertise unilatérale sur site, l’Expert d’assuré RV Expertises vous accompagne jusqu’au procès-verbal d’indemnisation à la juste valeur de votre sinistre. </p>

<h2>Comment se déroule une Expertise après un incendie ? </h2>

<ol>
    <li>Nous intervenons sur les lieux du sinistre, avant ou après le passage de l’Expert d’assurance, afin de procéder à une évaluation de vos dommages (gros oeuvre, second oeuvre, immobiliers, matériels, aménagements extérieurs, perte de jouissance) <u>couverts par vos garanties contractuelles.</u></li>

<li>Nous établissons un chiffrage estimatif de vos dommages appuyé par une analyse technique, </li>

<li>Nous remettons un rapport technique compilant éléments photographiques, analyses techniques et chiffrage de vos dommages, </li>

<li>Dès lors, le rapport d’expertise permet d’ouvrir un recours amiable avec votre assurance et son expert pour renégocier votre indemnisation ou demande le concours d’un sapiteur technique si besoin est. </li></ol>

<p>L’Expert d’assuré RV Expertises va être en relation avec votre Assurance, jusqu’à votre signature pour le Procès Verbal d’indemnisation qui met un terme aux phases d’expertises et vous garantie votre juste indemnisation. </p>

<h2>Que contient un rapport de contre-expertise sinistre incendie ? </h2>

<p>Le cabinet RV EXPERTISES s’est spécialisé dans l’évaluation des dégâts engendrés par un incendie afin de vous proposer un chiffrage estimatifs des pertes le plus complet.</p>
<p>Nous intervenons pour établir une indemnisation de votre sinistre à sa juste valeur afin que vos garanties assurantielles soient respectées.</p>

<p>Un rapport d’expertise incendie comprend :</p>

<ul><li>les circonstances et l’historique du sinistre,</li>
<li>le descriptif complet des biens endommagés ou totalement détruits,</li>
<li>la valeur des biens en fonction des factures et prix du marché,</li>
<li>les mesures conservatoires.</li></ul>

<p><u>Bon à savoir :</u> Penser à réunir toutes vos factures, devis ou autres documents attestants de l’existence des biens matériels détruits. </p>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/incendie/' className='active'>Après un incendie</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details